@import "~bootstrap/dist/css/bootstrap.min.css";
@import '~react-toastify/dist/ReactToastify.css';
@import '~react-phone-input-2/lib/style.css';
@import url("https://cdn.syncfusion.com/ej2/bootstrap4.css");

@import './colors.css';

.br_mybrand_mtr {
    margin-top: 1rem;
}

.br_mybrand_hmtr {
    margin-top: 0.5rem;
}

.br_mybrand_close_alert_window {
    cursor: pointer;
}

.br_mybrand_success_email_status {
    color: #28a745;
    margin-left: 1rem;
}

.br_mybrand_alert_email_status {
    color: #dc3545;
    cursor: pointer;
    margin-left: 1rem;
}

.br_mybrand_info_test_mail{
    margin-left: .8rem;
    margin-bottom: 0.5rem;
    font-size: .8rem;
}

.br_mybrand_send_icon{
    cursor: pointer;
    margin-right: 15px;
    font-size: 1.4rem;
    color: var(--primary--color)
}

.br_mybrand_halfbold {
    font-weight: 500;
}

.br_mybrand_active_switch {
    margin-top: 0.5rem;
}

.br_mybrand_permissionGroupAction {
    margin-right: 5px;
}

.br_mybrandparent_info_row {
    margin-bottom: 1rem;
}

.br_mybrand_license_info_icons {
    font-size: 1.3rem;
    color: var(--primary--color)
}

.br_mybrand_user_license_info {
    font-size: 0.9rem;
}

.br_mybrand_license_duration_expired {
    color: var(--declined--color)
}

.br_mybrand_license_duration_limit {
    color: var(--pending--color)
}

.br_mybrand_license_duration_ok {
    color: var(--primary--color)
}

.br_mybrand_coi {
    cursor: pointer;
    font-size: 1.5rem;
    color: var(--busy--color);
    float: right;
}

.br_mybrand_tac{
    max-height: 30rem;
    overflow: auto;
}
